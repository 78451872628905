<template>
  <el-upload
    class="avatar-uploader"
    :action="domain"
    list-type="picture-card"
    :on-success="UploadFaceImg"
    :data="QiNiYunL"
    :before-upload="gettoken"
    :file-list="fileList"
    :on-remove="handleRemove"
    ref="imgsUploader"
    :limit="limit"
    :on-exceed="exceed"
  >
    <i slot="default" class="el-icon-plus avatar-uploader-icon"></i>
    <div slot="fileList" slot-scope="{ file }">
      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
      <span class="el-upload-list__item-actions">
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove()"
        >
          <i class="el-icon-delete avatar-uploader-icon"></i>
        </span>
      </span>
    </div>
  </el-upload>
</template>

<script>
export default {
  props:{
    limit:{
      type:Number,
      default:9
    },
    clear:{
      type:Boolean,
      default:false
    },
    urls:{
      type:String,
    }
  },
  data() {
    return {
      imgList: [],
      fileList: [],
      domain: "http://up-z0.qiniup.com/",
      upload_qiniu_addr: "https://img.curiousmore.com/",
      disabled: false,
      QiNiYunL: {
        key: "",
        token: "",
      },
    };
  },
  methods: {
    exceed(){
       this.$message("不能上传更多了")
    },
    clearAll(){
       this.$refs.imgsUploader.clearFiles();
    },
    handleRemove(file) {
      console.log(file);
      let imgIndex;
      if (file.response) {
        imgIndex = this.imgList.findIndex((item, index, arr) => {
          return item == this.upload_qiniu_addr + file.response.key;
        });
      } else {
        imgIndex = this.imgList.findIndex((item, index, arr) => {
          return item == file.url;
        });
      }

      this.imgList.splice(imgIndex, 1);
      this.$emit("getImgUrl", this.imgList.join(","));
    },

    UploadFaceImg(file) {
      this.imgList.push(this.upload_qiniu_addr + file.key);
      // console.log(this.fileList);
      this.$emit("getImgUrl", this.imgList.join(","));
    },
    gettoken() {
      let _this = this;
      var timestamp = new Date().getTime();
      let data = {
        key: timestamp + ".png",
        type: "pic",
      };

      return new Promise((resolve,reject)=>{
          _this
        .$axios({
          method: "post",
          url: "https://www.curiousmore.com/qmore/push/upload/getToken",
          data: data,
        })
        .then((res) => {
          _this.QiNiYunL.token = res.data.data;
          _this.QiNiYunL.key = data.key;
          resolve()
        })
        .catch((err) => {
          console.log(err);
          reject()
        });
      })

      
    },
  },
  watch: {
    clear(newV, oldV) {
      if (newV) {
        this.imgList = [];
        this.fileList = [];
        this.$refs.imgsUploader.clearFiles();
      }
    },
    urls: {
      immediate: true,
      handler(newV, oldV){
        if (newV) {
          this.fileList = [];
          this.imgList = []
          let url_list = newV.split(",");
          this.imgList = url_list;
          for (let i = 0; i < url_list.length; i++) {
            this.fileList.push({ url: url_list[i] });
          }
        }else{
           this.fileList = [];
            this.imgList = []
        }
      },
    },
  },
};
</script>

<style>
/* .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 14px;
  color: #8c939d;
  width: 5.6rem;
  height: 5.6rem;
  line-height: 5.6rem;
  text-align: center;
}
.avatar {
  width: 5.6rem;
  height: 5.6rem;
  display: block;
} */
</style>
